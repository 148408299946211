import React from 'react';

const VisibilityOn = () => (
  <>
    <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 31.5C26.3667 31.5 28.375 30.675 30.025 29.025C31.675 27.375 32.5 25.3667 32.5 23C32.5 20.6333 31.675 18.625 30.025 16.975C28.375 15.325 26.3667 14.5 24 14.5C21.6333 14.5 19.625 15.325 17.975 16.975C16.325 18.625 15.5 20.6333 15.5 23C15.5 25.3667 16.325 27.375 17.975 29.025C19.625 30.675 21.6333 31.5 24 31.5ZM24 28.6C22.4333 28.6 21.1083 28.0583 20.025 26.975C18.9417 25.8917 18.4 24.5667 18.4 23C18.4 21.4333 18.9417 20.1083 20.025 19.025C21.1083 17.9417 22.4333 17.4 24 17.4C25.5667 17.4 26.8917 17.9417 27.975 19.025C29.0583 20.1083 29.6 21.4333 29.6 23C29.6 24.5667 29.0583 25.8917 27.975 26.975C26.8917 28.0583 25.5667 28.6 24 28.6ZM24 38C19.1333 38 14.7333 36.6167 10.8 33.85C6.86667 31.0833 3.93333 27.4667 2 23C3.93333 18.5333 6.86667 14.9167 10.8 12.15C14.7333 9.38333 19.1333 8 24 8C28.8667 8 33.2667 9.38333 37.2 12.15C41.1333 14.9167 44.0667 18.5333 46 23C44.0667 27.4667 41.1333 31.0833 37.2 33.85C33.2667 36.6167 28.8667 38 24 38ZM24 35C28.0333 35 31.7417 33.9083 35.125 31.725C38.5083 29.5417 41.0833 26.6333 42.85 23C41.0833 19.3667 38.5083 16.4583 35.125 14.275C31.7417 12.0917 28.0333 11 24 11C19.9667 11 16.2583 12.0917 12.875 14.275C9.49167 16.4583 6.9 19.3667 5.1 23C6.9 26.6333 9.49167 29.5417 12.875 31.725C16.2583 33.9083 19.9667 35 24 35Z" fill="#888888" />
    </svg>
  </>
);

export default VisibilityOn;
