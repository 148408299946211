import React from 'react';

const OrdersIcon = props => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1801_4807)">
      <path d="M5.68359 0.0205231C5.12988 0.114273 4.65234 0.51857 4.46484 1.05177L4.39746 1.24513L3.83789 1.25978L3.27539 1.27443L3.20508 1.35646C3.1377 1.43556 3.13477 1.45021 3.13477 1.96291V2.48732L1.98633 2.49611C0.723633 2.5049 0.74707 2.50197 0.65918 2.68361C0.594727 2.8213 0.591797 14.6543 0.65625 14.8096C0.744141 15.0147 0.290039 15 6.25488 15C11.2236 15 11.6426 14.9971 11.7188 14.9502C11.7656 14.9238 11.8154 14.8711 11.833 14.833C11.8564 14.7832 11.8652 13.0049 11.8652 8.73341V2.70412L11.8066 2.62794C11.7715 2.584 11.6953 2.53712 11.6367 2.51955C11.5723 2.50197 11.1006 2.49025 10.4531 2.49025H9.375V1.9922C9.375 1.44435 9.35449 1.35939 9.21387 1.29494C9.16406 1.2715 8.95898 1.25978 8.61621 1.25978H8.0918L8.07422 1.16017C8.0625 1.10451 8.00977 0.969742 7.9541 0.858414C7.75781 0.465836 7.32715 0.134781 6.89355 0.0439606C6.70605 0.00294495 5.87695 -0.0117035 5.68359 0.0205231ZM6.89355 0.688492C7.08105 0.755875 7.33008 0.98732 7.40918 1.16896C7.44141 1.24806 7.48242 1.40334 7.49707 1.51173C7.52051 1.66115 7.54395 1.72853 7.60254 1.78419C7.67578 1.86037 7.68164 1.86037 8.21777 1.86916L8.75977 1.87794V2.18556V2.49025H6.25488H3.75V2.18263V1.87502H4.24805C4.91895 1.87502 4.96289 1.85158 5.00977 1.47658C5.0625 1.04591 5.36133 0.717789 5.76855 0.641617C5.99414 0.600601 6.73242 0.632828 6.89355 0.688492ZM11.25 8.75978V14.3848H6.25488H1.25977V8.75978V3.13478H6.25488H11.25V8.75978Z" fill="currentColor" />
      <path d="M2.06543 3.77638C2.0332 3.79103 1.98047 3.83205 1.94824 3.8672C1.88965 3.93166 1.88965 3.97853 1.88086 8.71291C1.87207 13.0957 1.875 13.5 1.91894 13.582C1.94824 13.6348 2.00098 13.6904 2.03906 13.708C2.0918 13.7315 3.2666 13.7402 6.26367 13.7402C10.834 13.7402 10.4941 13.7549 10.5908 13.5645C10.6553 13.4385 10.6582 4.09572 10.5938 3.94044C10.5059 3.73537 10.8223 3.75002 6.24902 3.75294C3.98144 3.75294 2.09766 3.76466 2.06543 3.77638ZM9.99023 8.74513V13.125H6.24023H2.49023V8.74513V4.36525H6.24023H9.99023V8.74513Z" fill="currentColor" />
      <path d="M4.21289 5.67484C4.04883 5.77737 4.01953 6.01761 4.15723 6.1553L4.24219 6.24026H6.22266C7.61719 6.24026 8.2207 6.23148 8.27344 6.20804C8.44922 6.12601 8.48145 5.85062 8.33203 5.70999L8.25586 5.63968L6.27539 5.63382C4.46484 5.62796 4.28613 5.63089 4.21289 5.67484Z" fill="currentColor" />
      <path d="M4.2334 7.53519C4.125 7.59378 4.07227 7.68753 4.07227 7.81644C4.07227 7.91898 4.08984 7.95999 4.15723 8.0303L4.24219 8.11526H6.22266C7.69336 8.11526 8.2207 8.10648 8.27637 8.08011C8.37012 8.03909 8.4375 7.91605 8.4375 7.79007C8.4375 7.72562 8.4082 7.66995 8.33789 7.59964L8.23828 7.50003H6.2666C4.88672 7.50296 4.27441 7.51175 4.2334 7.53519Z" fill="currentColor" />
      <path d="M4.22168 9.41605C4.05176 9.5098 4.01953 9.76468 4.15723 9.9053L4.24219 9.99026H6.22266C7.69336 9.99026 8.2207 9.98148 8.27637 9.95511C8.37012 9.91409 8.4375 9.79105 8.4375 9.66507C8.4375 9.60062 8.4082 9.54495 8.33789 9.47464L8.23828 9.37503H6.2666C4.68457 9.37796 4.27734 9.38382 4.22168 9.41605Z" fill="currentColor" />
      <path d="M12.6504 1.92483C12.6094 1.9512 12.5596 2.00394 12.542 2.04202C12.5186 2.09183 12.5098 3.62698 12.5098 7.18655V12.2608L12.8291 13.2158C13.002 13.7373 13.1631 14.1973 13.1807 14.2354C13.2275 14.3203 13.4004 14.3936 13.5059 14.3701C13.5498 14.3614 13.6143 14.3233 13.6494 14.2852C13.6904 14.2442 13.8369 13.8457 14.0479 13.21L14.3848 12.2022V7.18362C14.3848 1.68753 14.3965 2.01565 14.2119 1.9219C14.0771 1.85159 12.7646 1.85452 12.6504 1.92483ZM13.7402 3.12015V3.75003H13.4326H13.125V3.12015V2.49026H13.4326H13.7402V3.12015ZM13.7402 7.80765V11.25H13.4326H13.125V7.80765V4.36526H13.4326H13.7402V7.80765ZM13.7402 12.0176C13.7402 12.126 13.6934 12.3106 13.5879 12.627L13.4385 13.0811L13.2803 12.6123C13.1807 12.3076 13.125 12.0967 13.125 12.0059V11.8653H13.4326H13.7402V12.0176Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_1801_4807">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>


);

export default OrdersIcon;
