import { handleActions } from 'redux-actions';

import {
  getVehicleTypeAction,
  deleteVehicleTypeAction,
  createVehicleTypeAction,
  updateVehicleTypeAction,
  // models
  updateVehicleModelAction,
  deleteVehicleModelAction,
} from './VehicleActionConfig';

// reducers
const defaultState = {
  vehicles: {
    data: [],
    meta: {
      total: 0,
    },
  },
};

const reducer = handleActions({
  [getVehicleTypeAction](state, { payload }) {
    return {
      ...state,
      vehicles: payload,
    };
  },
  [createVehicleTypeAction](state, { payload }) {
    return {
      ...state,
      vehicles: {
        ...state.vehicles,
        data: [
          { ...payload },
          ...state.vehicles.data,
        ],
        meta: {
          ...state.vehicles.meta,
          total: state.vehicles.meta.total + 1,
        },
      },
    };
  },
  [updateVehicleTypeAction](state, { payload }) {
    return {
      ...state,
      vehicles: {
        ...state.vehicles,
        data: state.vehicles.data
          .map((vehicle) => {
            if (vehicle.id === payload.id) {
              return {
                ...vehicle,
                ...payload,
              };
            }

            return vehicle;
          }),
      },
    };
  },
  [deleteVehicleTypeAction](state, { params }) {
    return {
      ...state,
      vehicles: {
        ...state.vehicles,
        data: state.vehicles.data
          .filter(vehicle => vehicle.id !== params.vehicleId),
        meta: {
          ...state.vehicles.meta,
          total: state.vehicles.meta.total - 1,
        },
      },
    };
  },

  // models
  [updateVehicleModelAction](state, { payload }) {
    const newVehicles = state.vehicles.data.map((vehicle) => {
      const models = vehicle.models
        .map((model) => {
          if (model.id === payload.id) {
            return {
              ...model,
              ...payload,
            };
          }

          return model;
        });

      return {
        ...vehicle,
        models,
      };
    });

    return {
      ...state,
      vehicles: {
        ...state.vehicles,
        data: newVehicles,
      },
    };
  },
  [deleteVehicleModelAction](state, { params }) {
    const newVehicles = state.vehicles.data.map((vehicle) => {
      const models = vehicle.models
        .filter(model => model.id !== params.modelId);
      return {
        ...vehicle,
        models,
      };
    });

    return {
      ...state,
      vehicles: {
        ...state.vehicles,
        data: newVehicles,
      },
    };
  },
}, defaultState);

export default reducer;
