import React from 'react';

const TotalOrdersIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} fill="none" {...props}>
    <path
      fill="#8D222C"
      d="M11.9 4.755c-.228.061-1.002.984-4.2 4.975l-3.92 4.904v25.092l.254.282.255.29 14.361.026c13.834.018 14.362.009 14.617-.14.21-.132 1.116-1.653 4.113-6.891l3.84-6.732V5.22l-.272-.264-.263-.255-14.256-.017c-8.42-.01-14.37.017-14.529.07Zm11.154 2.057c-.088.114-1.503 1.793-3.147 3.726l-2.988 3.524h-5.124c-4.948 0-5.124-.008-5.01-.158.062-.088 1.398-1.775 2.97-3.735l2.857-3.577h10.609l-.167.22Zm15.029-.027c-.097.114-1.512 1.776-3.146 3.7-1.627 1.925-3.015 3.525-3.068 3.542-.053.018-2.874.027-6.275.018l-6.188-.026 3.155-3.71 3.147-3.717h12.551l-.176.193Zm-1.485 24.073a662.36 662.36 0 0 1-2.813 4.905c-.017.017-.035-4.685-.035-10.468V14.801l2.795-3.287 2.786-3.287.026 8.885.018 8.877-2.777 4.87Zm-4.694-3.7v11.25H5.625v-22.5h26.28v11.25Z"
    />
    <path
      fill="#8D222C"
      d="M14.616 21.665c-.29.123-.553.519-.553.835 0 .105.061.308.14.448.255.51.352.519 4.57.519 4.263 0 4.29 0 4.553-.572.08-.158.14-.334.14-.395s-.06-.237-.14-.395c-.264-.572-.29-.572-4.579-.572-3.076.009-3.902.035-4.13.132Z"
    />
  </svg>
);

export default TotalOrdersIcon;
