import React from 'react';

const SystemIcon = props => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1164_7430)">
      <path d="M26.8758 30H3.12608C0.731067 30 0.00115967 29.2701 0.00115967 26.8751V6.87534C0.00115967 6.53027 0.281155 6.25027 0.626229 6.25027H29.3763C29.7201 6.25027 30.0014 6.53027 30.0014 6.87534V26.8751C30.0014 29.2701 29.2715 30 26.8764 30H26.8758ZM1.25055 7.50009V26.8752C1.25055 28.5926 1.40812 28.7502 3.12554 28.7502H26.8753C28.5927 28.7502 28.7503 28.5926 28.7503 26.8752V7.50009H1.25055Z" fill="currentColor" />
      <path d="M5.62566 27.4998C4.5919 27.4998 3.75067 26.6586 3.75067 25.6248C3.75067 24.5911 4.5919 23.7498 5.62566 23.7498C6.65943 23.7498 7.50066 24.5911 7.50066 25.6248C7.50066 26.6586 6.65943 27.4998 5.62566 27.4998ZM5.62566 25C5.28059 25 5.00059 25.2812 5.00059 25.6251C5.00059 25.9689 5.28059 26.2501 5.62566 26.2501C5.97074 26.2501 6.25073 25.9689 6.25073 25.6251C6.25073 25.2812 5.97074 25 5.62566 25Z" fill="currentColor" />
      <path d="M5.62566 19.9999C4.5919 19.9999 3.75067 19.1586 3.75067 18.1249C3.75067 17.0911 4.5919 16.2499 5.62566 16.2499C6.65943 16.2499 7.50066 17.0911 7.50066 18.1249C7.50066 19.1586 6.65943 19.9999 5.62566 19.9999ZM5.62566 17.5C5.28059 17.5 5.00059 17.7813 5.00059 18.1251C5.00059 18.4689 5.28059 18.7502 5.62566 18.7502C5.97074 18.7502 6.25073 18.4689 6.25073 18.1251C6.25073 17.7813 5.97074 17.5 5.62566 17.5Z" fill="currentColor" />
      <path d="M28.7508 22.5H1.25105C0.905972 22.5 0.625977 22.2188 0.625977 21.875C0.625977 21.5311 0.905972 21.2499 1.25105 21.2499H28.7508C29.0946 21.2499 29.3758 21.5311 29.3758 21.875C29.3758 22.2188 29.0946 22.5 28.7508 22.5Z" fill="currentColor" />
      <path d="M28.7508 15.0001H1.25105C0.905972 15.0001 0.625977 14.7201 0.625977 14.375C0.625977 14.0299 0.905972 13.7499 1.25105 13.7499H28.7508C29.0946 13.7499 29.3758 14.0299 29.3758 14.375C29.3758 14.7201 29.0946 15.0001 28.7508 15.0001Z" fill="currentColor" />
      <path d="M25.0008 26.25H18.7506C18.4068 26.25 18.1255 25.9688 18.1255 25.6249C18.1255 25.2811 18.4068 24.9999 18.7506 24.9999H25.0008C25.3446 24.9999 25.6258 25.2811 25.6258 25.6249C25.6258 25.9688 25.3446 26.25 25.0008 26.25Z" fill="currentColor" />
      <path d="M25.0008 18.75H18.7506C18.4068 18.75 18.1255 18.4688 18.1255 18.125C18.1255 17.7812 18.4068 17.4999 18.7506 17.4999H25.0008C25.3446 17.4999 25.6258 17.7812 25.6258 18.125C25.6258 18.4688 25.3446 18.75 25.0008 18.75Z" fill="currentColor" />
      <path d="M5.62566 12.4999C4.5919 12.4999 3.75067 11.6587 3.75067 10.6249C3.75067 9.59114 4.5919 8.74991 5.62566 8.74991C6.65943 8.74991 7.50066 9.59114 7.50066 10.6249C7.50066 11.6587 6.65943 12.4999 5.62566 12.4999ZM5.62566 10C5.28059 10 5.00059 10.2813 5.00059 10.6251C5.00059 10.9689 5.28059 11.2502 5.62566 11.2502C5.97074 11.2502 6.25073 10.9689 6.25073 10.6251C6.25073 10.2813 5.97074 10 5.62566 10Z" fill="currentColor" />
      <path d="M25.0008 11.2501H18.7506C18.4068 11.2501 18.1255 10.9701 18.1255 10.625C18.1255 10.2799 18.4068 9.99994 18.7506 9.99994H25.0008C25.3446 9.99994 25.6258 10.2799 25.6258 10.625C25.6258 10.9701 25.3446 11.2501 25.0008 11.2501Z" fill="currentColor" />
      <path d="M29.3754 7.50009H0.625351C0.385325 7.50009 0.166647 7.36261 0.0616211 7.14644C-0.0421731 6.93027 -0.0132929 6.67266 0.136535 6.48515L5.13644 0.235001C5.25509 0.0874711 5.43527 0 5.62528 0H24.3752C24.5652 0 24.7439 0.0862175 24.864 0.235001L29.8639 6.48515C30.014 6.67265 30.0439 6.93026 29.9401 7.14644C29.8338 7.36261 29.6139 7.50009 29.3751 7.50009H29.3754ZM1.9266 6.25017H28.0752L24.0752 1.25026H5.92526L1.92643 6.25017H1.9266Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_1164_7430">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SystemIcon;
