/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
// Component -> Saga -> Redux
// actionNameSagaAction - Saga (Component to Saga)
// actionNameReduxAction - Redux (Saga to Redux or Component to Redux)
export const setContainerClassnamesAction = createAction('SRS_MENU_SET_CONTAINER_CLASSNAMES');
export const addContainerClassnameAction = createAction('SRS_MENU_ADD_CONTAINER_CLASSNAME');
export const clickMobileMenuAction = createAction('SRS_MENU_CLICK_MOBILE_MENU');
export const changeDefaultClassesAction = createAction('SRS_MENU_CHANGE_DEFAULT_CLASSES');
export const changeHasSubItemStatusAction = createAction('SRS_MENU_CHANGE_HAS_SUB_ITEM_STATUS');
