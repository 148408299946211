/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const getTransactionsAction = createAction('PIEZA_ADMIN_GET_TRANSACTIONS');
export const getOrdersTotalCountsAction = createAction('PIEZA_ADMIN_GET_ORDERS_TOTAL_COUNTS');
export const updateOrderStatusAction = createAction('PIEZA_ADMIN_UPDATE_ORDER_STATUS');
export const getRejectionReasonsAction = createAction('PIEZA_ADMIN_GET_REJECTION_REASONS');
export const showOrderDetailsAction = createAction('PIEZA_ADMIN_SHOW_ORDER_DETAILS');


export const getPabiliTransactionsAction = createAction('PIEZA_ADMIN_GET_PABILI_TRANSACTIONS');
export const getPabiliOrdersTotalCountsAction = createAction('PIEZA_ADMIN_GET_PABILI_ORDERS_TOTAL_COUNTS');
export const updatePabiliOrderStatusAction = createAction('PIEZA_ADMIN_UPDATE_PABILI_ORDER_STATUS');
export const getPabiliRejectionReasonsAction = createAction('PIEZA_ADMIN_GET_PABILI_REJECTION_REASONS');
export const showPabiliOrderDetailsAction = createAction('PIEZA_ADMIN_SHOW_PABILI_ORDER_DETAILS');

export const acceptPabiliOrderAction = createAction('PIEZA_ADMIN_ACCEPT_PABILI_ORDER');
