import { handleActions } from 'redux-actions';

import {
  getVoucherAction,
  deleteVoucherAction,
  createVoucherAction,
  updateVoucherAction,
} from './VoucherActionConfig';

// reducers
const defaultState = {
  vouchers: {
    data: [],
    meta: {
      total: 0,
    },
  },
};

const reducer = handleActions({
  [getVoucherAction](state, { payload }) {
    return {
      ...state,
      vouchers: payload,
    };
  },
  [createVoucherAction](state, { payload }) {
    return {
      ...state,
      vouchers: {
        ...state.vouchers,
        data: [
          { ...payload },
          ...state.vouchers.data,
        ],
        meta: {
          ...state.vouchers.meta,
          total: state.vouchers.meta.total + 1,
        },
      },
    };
  },
  [updateVoucherAction](state, { payload }) {
    return {
      ...state,
      vouchers: {
        ...state.vouchers,
        data: state.vouchers.data
          .map((voucher) => {
            if (voucher.id === payload.id) {
              return {
                ...voucher,
                ...payload,
              };
            }

            return voucher;
          }),
      },
    };
  },
  [deleteVoucherAction](state, { params }) {
    return {
      ...state,
      vouchers: {
        ...state.vouchers,
        data: state.vouchers.data
          .filter(voucher => voucher.id !== params.voucherId),
        meta: {
          ...state.vouchers.meta,
          total: state.vouchers.meta.total - 1,
        },
      },
    };
  },
}, defaultState);

export default reducer;
