import React from 'react';

const ArrowDown = props => (
  <svg
    width="20"
    height="13"
    viewBox="0 0 20 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9.99996 11.5L1.66663 3.01943L3.15968 1.5L9.99996 8.46113L16.8402 1.5L18.3333 3.01943L9.99996 11.5Z" fill="currentColor" stroke="currentColor" />
  </svg>
);

export default ArrowDown;
