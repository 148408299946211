import React from 'react';

const BackArrowCircle = props => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M11.8438 16.4062L8.59375 13.1562C8.40625 12.9687 8.3125 12.75 8.3125 12.5C8.3125 12.25 8.40625 12.0313 8.59375 11.8438L11.875 8.5625C12.0417 8.39583 12.2552 8.31771 12.5156 8.32812C12.776 8.33854 12.9896 8.42708 13.1562 8.59375C13.3438 8.78125 13.4375 9 13.4375 9.25C13.4375 9.5 13.3438 9.71875 13.1562 9.90625L11.5 11.5625H16.2812C16.5312 11.5625 16.7448 11.6562 16.9219 11.8438C17.099 12.0313 17.1875 12.25 17.1875 12.5C17.1875 12.7708 17.099 12.9948 16.9219 13.1719C16.7448 13.349 16.5208 13.4375 16.25 13.4375H11.5L13.1875 15.125C13.3542 15.2917 13.4323 15.5052 13.4219 15.7656C13.4115 16.026 13.3229 16.2396 13.1562 16.4062C12.9687 16.5938 12.75 16.6875 12.5 16.6875C12.25 16.6875 12.0313 16.5938 11.8438 16.4062ZM12.5 25C14.2708 25 15.9167 24.6823 17.4375 24.0469C18.9583 23.4115 20.2812 22.5312 21.4062 21.4062C22.5312 20.2812 23.4115 18.9583 24.0469 17.4375C24.6823 15.9167 25 14.2708 25 12.5C25 10.75 24.6823 9.11458 24.0469 7.59375C23.4115 6.07292 22.5312 4.75 21.4062 3.625C20.2812 2.5 18.9583 1.61458 17.4375 0.96875C15.9167 0.322917 14.2708 0 12.5 0C10.75 0 9.11458 0.322917 7.59375 0.96875C6.07292 1.61458 4.75 2.5 3.625 3.625C2.5 4.75 1.61458 6.07292 0.96875 7.59375C0.322916 9.11458 0 10.75 0 12.5C0 14.2708 0.322916 15.9167 0.96875 17.4375C1.61458 18.9583 2.5 20.2812 3.625 21.4062C4.75 22.5312 6.07292 23.4115 7.59375 24.0469C9.11458 24.6823 10.75 25 12.5 25ZM12.5 23.125C9.47917 23.125 6.95312 22.1094 4.92188 20.0781C2.89062 18.0469 1.875 15.5208 1.875 12.5C1.875 9.47917 2.89062 6.95312 4.92188 4.92188C6.95312 2.89062 9.47917 1.875 12.5 1.875C15.5208 1.875 18.0469 2.89062 20.0781 4.92188C22.1094 6.95312 23.125 9.47917 23.125 12.5C23.125 15.5208 22.1094 18.0469 20.0781 20.0781C18.0469 22.1094 15.5208 23.125 12.5 23.125Z" fill="#4A4A4A" />
  </svg>

);

export default BackArrowCircle;
