/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const getConfigureProductsAction = createAction('PIEZA_ADMIN_GET_CONFIGURE_PRODUCTS');
export const getConfigureProductCategoriesAction = createAction('PIEZA_ADMIN_GET_CONFIGURE_PRODUCT_CATEGORIES');
export const getConfigureVehicleMakesAction = createAction('PIEZA_ADMIN_GET_CONFIGURE_VEHICLE_MAKES');
export const getConfigureVehicleTypesAction = createAction('PIEZA_ADMIN_GET_CONFIGURE_VEHICLE_TYPES');
export const getConfigureVendorsAction = createAction('PIEZA_ADMIN_GET_CONFIGURE_VENDORS');

export const updateProductVendorAction = createAction('PIEZA_ADMIN_UPDATE_PRODUCT_VENDOR');
export const updateProductCategoryAction = createAction('PIEZA_ADMIN_UPDATE_PRODUCT_CATEGORY');
export const updateProductVehicleMakesAndModelsAction = createAction('PIEZA_ADMIN_UPDATE_PRODUCT_VEHICLE_MAKES_AND_MODELS');
export const updateProductShippingClassAction = createAction('PIEZA_ADMIN_UPDATE_PRODUCT_SHIPPING_CLASS');

export const deleteProductsAction = createAction('PIEZA_ADMIN_DELETE_PRODUCTS');
export const productsBulkUpdateAction = createAction('PIEZA_ADMIN_PRODUCTS_BULK_UPDATE');
export const getProductsAction = createAction('PIEZA_ADMIN_GET_PRODUCTS');
export const createProductAction = createAction('PIEZA_ADMIN_CREATE_PRODUCT');
export const showProductAction = createAction('PIEZA_ADMIN_SHOW_PRODUCT');
export const updateProductAction = createAction('PIEZA_ADMIN_UPDATE_PRODUCT');
export const deleteProductAction = createAction('PIEZA_ADMIN_DELETE_PRODUCT');

export const exportProductTemplateAction = createAction('PIEZA_ADMIN_EXPORT_PRODUCT_TEMPLATE');
export const importProductAction = createAction('PIEZA_ADMIN_IMPORT_PRODUCT');

export const bulkDeleteProductsAction = createAction('PIEZA_ADMIN_BULK_DELETE_PRODUCTS');
export const bulkSetAvailableProductsAction = createAction('PIEZA_ADMIN_BULK_SET_AVAILABLE_PRODUCTS');
export const bulkSetUnavailableProductsAction = createAction('PIEZA_ADMIN_BULK_SET_UNAVAILABLE_PRODUCTS');
export const getImportLogProductAction = createAction('PIEZA_ADMIN_PRODUCT_GET_IMPORT_LOG');
export const generateUploadSignedUrlAction = createAction('PIEZA_ADMIN_GENERATE_UPLOAD_SIGNED_URL');
export const getShippingClassesAction = createAction('PIEZA_ADMIN_SHIPPING_CLASSES');
