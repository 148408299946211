import React from 'react';
import PropTypes from 'prop-types';

import LoadingRoot from '@shared/utils/Loading/LoadingRoot';
import ConfirmDialogRoot from '@shared/utils/Modal/ConfirmDialog';

const MainWrapper = ({
  children,
}) => (
  <>
    {children}
    <ConfirmDialogRoot />
    <LoadingRoot cover />
    {/* <DangerToastRoot /> */}
  </>
);

MainWrapper.propTypes = {
  children: PropTypes.any.isRequired,
};

export {
  MainWrapper,
};

export default MainWrapper;
