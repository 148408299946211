import React from 'react';

const PromoIcon = props => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1.35074 15.4254L15.2894 1.4867C15.4631 1.31204 15.6697 1.17357 15.8973 1.0793C16.1249 0.985033 16.3689 0.936835 16.6152 0.9375H26.0707C26.8163 0.938353 27.5312 1.23494 28.0585 1.7622C28.5857 2.28947 28.8823 3.00434 28.8832 3.75V13.1882C28.8828 13.4442 28.8301 13.6974 28.7285 13.9324C28.6268 14.1674 28.4782 14.3791 28.2919 14.5547L13.4641 28.4839C13.2043 28.7295 12.8601 28.8661 12.5026 28.8653C12.4851 28.8653 12.4676 28.865 12.4499 28.8643C12.264 28.8581 12.0812 28.8149 11.9122 28.737C11.7433 28.6592 11.5916 28.5484 11.4661 28.4112L1.31007 17.3718C1.06367 17.1052 0.930228 16.7534 0.937812 16.3904C0.945395 16.0275 1.09341 15.6816 1.35074 15.4254ZM12.5255 26.7931L27.0082 13.1882V3.75C27.0079 3.50145 26.909 3.26316 26.7333 3.08741C26.5575 2.91166 26.3192 2.81279 26.0707 2.8125H16.6152L2.99447 16.4333L12.5255 26.7931Z" fill="#1E090C" />
    <path d="M21.1488 5.625C21.7514 5.625 22.3405 5.8037 22.8416 6.13849C23.3426 6.47329 23.7331 6.94914 23.9637 7.50589C24.1944 8.06263 24.2547 8.67526 24.1371 9.26629C24.0196 9.85733 23.7294 10.4002 23.3033 10.8263C22.8772 11.2525 22.3343 11.5426 21.7432 11.6602C21.1522 11.7778 20.5396 11.7174 19.9828 11.4868C19.4261 11.2562 18.9502 10.8657 18.6154 10.3646C18.2806 9.86357 18.1019 9.27449 18.1019 8.67188C18.1028 7.86407 18.4241 7.08962 18.9953 6.51842C19.5665 5.94722 20.341 5.62591 21.1488 5.625ZM21.1488 9.84375C21.3806 9.84375 21.6071 9.77502 21.7999 9.64625C21.9926 9.51749 22.1428 9.33446 22.2315 9.12033C22.3202 8.9062 22.3434 8.67057 22.2982 8.44325C22.2529 8.21593 22.1413 8.00712 21.9774 7.84323C21.8136 7.67934 21.6047 7.56773 21.3774 7.52252C21.1501 7.4773 20.9145 7.50051 20.7003 7.5892C20.4862 7.6779 20.3032 7.8281 20.1744 8.02082C20.0457 8.21353 19.9769 8.4401 19.9769 8.67188C19.9773 8.98257 20.1009 9.28043 20.3206 9.50012C20.5402 9.71981 20.8381 9.84339 21.1488 9.84375Z" fill="#1E090C" />
  </svg>
);

export default PromoIcon;
