import React from 'react';

const CancelIcon = props => (
  <svg
    width={40}
    height={40}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 18.177 14.323 12.5 12.5 14.323 18.177 20 12.5 25.677l1.823 1.823L20 21.823l5.677 5.677 1.823-1.823L21.823 20l5.677-5.677-1.823-1.823L20 18.177Z"
      fill="#fff"
    />
  </svg>
);

export default CancelIcon;
