import { handleActions } from 'redux-actions';

import constantsConfig from '@config/constants';
import {
  setContainerClassnamesAction,
  addContainerClassnameAction,
  clickMobileMenuAction,
  changeDefaultClassesAction,
  changeHasSubItemStatusAction,
} from './MenuActionConfig';

const { defaultMenuType, subHiddenBreakpoint, menuHiddenBreakpoint } = constantsConfig;

// reducers
const defaultState = {
  containerClassnames: defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
  menuClickCount: 0,
  selectedMenuHasSubItems: defaultMenuType === 'menu-default', // if you use menu-sub-hidden as default menu type, set value of this variable to false
};

const reducer = handleActions({
  [setContainerClassnamesAction](state, { payload }) {
    const { strCurrentClasses, selectedMenuHasSubItems } = payload;
    let { clickIndex } = payload;

    const currentClasses = strCurrentClasses ? strCurrentClasses.split(' ').filter(x => x !== '') : '';
    let nextClasses = '';

    if (!selectedMenuHasSubItems) {
      if (currentClasses.includes('menu-default') && (clickIndex % 4 === 0 || clickIndex % 4 === 3)) {
        clickIndex = 1;
      }
      if (currentClasses.includes('menu-sub-hidden') && (clickIndex % 4 === 2)) {
        clickIndex = 0;
      }
      if (currentClasses.includes('menu-hidden') && (clickIndex % 4 === 2 || clickIndex % 4 === 3)) {
        clickIndex = 0;
      }
    }

    if (clickIndex % 4 === 0) {
      if (currentClasses.includes('menu-default') && currentClasses.includes('menu-sub-hidden')) {
        nextClasses = 'menu-default menu-sub-hidden';
      } else if (currentClasses.includes('menu-default')) {
        nextClasses = 'menu-default';
      } else if (currentClasses.includes('menu-sub-hidden')) {
        nextClasses = 'menu-sub-hidden';
      } else if (currentClasses.includes('menu-hidden')) {
        nextClasses = 'menu-hidden';
      }
      clickIndex = 0;
    } else if (clickIndex % 4 === 1) {
      if (currentClasses.includes('menu-default') && currentClasses.includes('menu-sub-hidden')) {
        nextClasses = 'menu-default menu-sub-hidden main-hidden sub-hidden';
      } else if (currentClasses.includes('menu-default')) {
        nextClasses = 'menu-default sub-hidden';
      } else if (currentClasses.includes('menu-sub-hidden')) {
        nextClasses = 'menu-sub-hidden main-hidden sub-hidden';
      } else if (currentClasses.includes('menu-hidden')) {
        nextClasses = 'menu-hidden main-show-temporary';
      }
    } else if (clickIndex % 4 === 2) {
      if (currentClasses.includes('menu-default') && currentClasses.includes('menu-sub-hidden')) {
        nextClasses = 'menu-default menu-sub-hidden sub-hidden';
      } else if (currentClasses.includes('menu-default')) {
        nextClasses = 'menu-default main-hidden sub-hidden';
      } else if (currentClasses.includes('menu-sub-hidden')) {
        nextClasses = 'menu-sub-hidden sub-hidden';
      } else if (currentClasses.includes('menu-hidden')) {
        nextClasses = 'menu-hidden main-show-temporary sub-show-temporary';
      }
    } else if (clickIndex % 4 === 3) {
      if (currentClasses.includes('menu-default') && currentClasses.includes('menu-sub-hidden')) {
        nextClasses = 'menu-default menu-sub-hidden sub-show-temporary';
      } else if (currentClasses.includes('menu-default')) {
        nextClasses = 'menu-default sub-hidden';
      } else if (currentClasses.includes('menu-sub-hidden')) {
        nextClasses = 'menu-sub-hidden sub-show-temporary';
      } else if (currentClasses.includes('menu-hidden')) {
        nextClasses = 'menu-hidden main-show-temporary';
      }
    }
    if (currentClasses.includes('menu-mobile')) {
      nextClasses += ' menu-mobile';
    }

    return {
      ...state,
      containerClassnames: nextClasses,
      menuClickCount: clickIndex,
    };
  },
  [addContainerClassnameAction](state, { payload }) {
    const { classname, strCurrentClasses } = payload;
    const newClasses = !strCurrentClasses.indexOf(classname) > -1 ? `${strCurrentClasses} ${classname}` : strCurrentClasses;

    return { ...state, containerClassnames: newClasses };
  },
  [clickMobileMenuAction](state, { payload }) {
    const { strCurrentClasses } = payload;

    const currentClasses = strCurrentClasses ? strCurrentClasses.split(' ').filter(x => x !== '' && x !== 'sub-show-temporary') : '';
    let nextClasses = '';

    if (currentClasses.includes('main-show-temporary')) {
      nextClasses = (currentClasses.filter(x => x !== 'main-show-temporary')).join(' ');
    } else {
      nextClasses = `${currentClasses.join(' ')} main-show-temporary`;
    }

    return {
      ...state,
      containerClassnames: nextClasses,
      menuClickCount: 0,
    };
  },
  [changeDefaultClassesAction](state, { payload }) {
    return { ...state, containerClassnames: payload };
  },
  [changeHasSubItemStatusAction](state, { payload }) {
    return { ...state, selectedMenuHasSubItems: payload };
  },
}, defaultState);

export default reducer;
