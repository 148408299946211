import { handleActions } from 'redux-actions';

import {
  getDashboardDataAction,
} from './DashboardDataActionConfig';

// reducers
const defaultState = {
  users: [],
};

const reducer = handleActions({

  [getDashboardDataAction](state, { payload }) {
    return {
      ...state,
      users: payload,
    };
  },

}, defaultState);

export default reducer;
