/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const getReasonsAction = createAction('PIEZA_ADMIN_GET_REASONS');
export const getReasonAction = createAction('PIEZA_ADMIN_GET_REASON');
export const createReasonAction = createAction('PIEZA_ADMIN_CREATE_REASON');
export const updateReasonAction = createAction('PIEZA_ADMIN_UPDATE_REASON');
export const deleteReasonAction = createAction('PIEZA_ADMIN_DELETE_REASON');
export const disableReasonAction = createAction('PIEZA_ADMIN_DISABLE_REASON');
export const enableReasonAction = createAction('PIEZA_ADMIN_ENABLE_REASON');
