import React from 'react';

const ArrowUp = () => (
  <>
    <svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.49996 1.5L1.16663 9.98057L2.65968 11.5L9.49996 4.53887L16.3402 11.5L17.8333 9.98057L9.49996 1.5Z" fill="currentColor" stroke="currentColor" />
    </svg>
  </>
);

export default ArrowUp;
