import { handleActions } from 'redux-actions';

import {
  getCategoryAction,
  deleteCategoryAction,
  deleteSubCategoryAction,
  createCategoryAction,
  updateCategoryAction,
} from './CategoryActionConfig';

// reducers
const defaultState = {
  categories: {
    data: [],
    meta: {
      total: 0,
    },
  },
};

const reducer = handleActions({
  [getCategoryAction](state, { payload }) {
    return {
      ...state,
      categories: payload,
    };
  },
  [createCategoryAction](state, { payload }) {
    return {
      ...state,
      categories: {
        ...state.categories,
        data: [
          { ...payload },
          ...state.categories.data,
        ],
        meta: {
          ...state.categories.meta,
          total: state.categories.meta.total + 1,
        },
      },
    };
  },
  [updateCategoryAction](state, { payload }) {
    return {
      ...state,
      categories: {
        ...state.categories,
        data: state.categories.data
          .map((category) => {
            if (category.id === payload.id) {
              return {
                ...category,
                ...payload,
              };
            }

            return category;
          }),
      },
    };
  },
  [deleteCategoryAction](state, { params }) {
    return {
      ...state,
      categories: {
        ...state.categories,
        data: state.categories.data
          .filter(category => category.id !== params.categoryId),
        meta: {
          ...state.categories.meta,
          total: state.categories.meta.total - 1,
        },
      },
    };
  },
  [deleteSubCategoryAction](state, { params }) {
    const newCategories = state.categories.data.map((category) => {
      const subCategories = category.sub_categories
        .filter(subCategory => subCategory.id !== params.categoryId);
      return {
        ...category,
        sub_categories: subCategories,
      };
    });

    return {
      ...state,
      categories: {
        ...state.categories,
        data: newCategories,
      },
    };
  },
}, defaultState);

export default reducer;
