import * as React from 'react';

const ReasonsIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={13}
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.948.016C3.692.172.932 1.816.188 4.04a3.783 3.783 0 0 0 .016 2.406c.425 1.2 1.45 2.261 2.873 2.983.22.11.404.205.41.211.013.013-.324.652-.49.921a9.3 9.3 0 0 1-.783 1.087c-.22.266-.254.321-.254.422 0 .153.117.297.267.324.147.028.866-.036 1.227-.113 1.117-.23 2.062-.731 2.913-1.548l.315-.3.784-.003c1.398-.006 2.591-.22 3.736-.676 1.567-.622 2.803-1.653 3.381-2.819.765-1.539.465-3.231-.805-4.572C12.655 1.185 10.954.38 9.005.105 8.628.053 8.399.035 7.652.001c-.125-.003-.444 0-.704.015Zm1.867.716c2.815.392 4.942 1.84 5.438 3.703.07.27.08.364.08.78 0 .417-.01.511-.08.78-.49 1.834-2.522 3.244-5.33 3.697-.344.055-.546.065-1.448.068l-1.047.006-.376.364c-.729.713-1.32 1.083-2.158 1.355-.4.132-.875.23-.841.175.012-.018.12-.178.242-.355.47-.688.942-1.616.945-1.857 0-.178-.076-.245-.486-.42C2.502 8.498 1.43 7.556.972 6.586c-.624-1.316-.324-2.732.811-3.88 1.092-1.098 2.8-1.82 4.783-2.02.42-.04 1.833-.012 2.249.046Z"
    />
    <path
      fill="currentColor"
      d="M6.994 1.647c-.973.193-1.707.912-1.9 1.86-.089.442-.015.671.226.717.132.024.288-.049.34-.165.021-.043.049-.19.064-.325.058-.508.407-1.003.875-1.248.331-.175.52-.217.897-.202.297.009.343.018.554.12.56.268.86.78.86 1.465 0 .517-.165.915-.508 1.218-.266.232-.72.404-1.08.404-.193 0-.346.058-.417.156-.04.061-.048.168-.048.676 0 .38.012.63.033.68.086.183.374.217.52.06.07-.073.074-.103.083-.501l.01-.422.143-.022a3.07 3.07 0 0 0 .912-.336c.45-.282.81-.775.952-1.301.082-.303.073-.949-.016-1.264-.214-.753-.74-1.3-1.456-1.518a2.603 2.603 0 0 0-1.044-.052ZM7.08 7.853a.345.345 0 0 0-.159.104c-.058.074-.064.123-.064.505 0 .245.015.453.033.499.086.184.374.217.52.061.07-.073.074-.104.083-.493.012-.413-.012-.547-.1-.62a.39.39 0 0 0-.313-.056Z"
    />
  </svg>
);
export default ReasonsIcon;
