/**
 * A standard custom button with loading if loading is true
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from 'reactstrap';
import LoadingBlock from '@shared/utils/LoadingBlock';

const CustomButton = ({
  children,
  loading,
  disabled,
  className,
  shadow,
  btnRef,
  ...rest
}) => (
  <Button
  // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    className={classNames('btn-multiple-state', className, {
      'show-spinner': loading,
      'btn-shadow': shadow,
    })}
    // if loading is defined override disable
    disabled={loading || disabled}
    ref={btnRef}
  >
    <span className="spinner d-inline-block">
      <LoadingBlock enabled className="loading-white" />
    </span>
    <span className="label">
      {children}
    </span>
  </Button>
);

CustomButton.defaultProps = {
  children: null,
  loading: false,
  disabled: false,
  shadow: true,
  className: '',
  btnRef: null,
};

CustomButton.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  shadow: PropTypes.bool,
  className: PropTypes.string,
  btnRef: PropTypes.any,
};

export default CustomButton;
