import moment from 'moment';
import numeral from 'numeral';
// eslint-disable-next-line
import packageJson from '@root/package.json';

const packageName = packageJson.name;

const currencySymbol = '₱';

const fixAbbreviation = (value) => {
  const stringedValue = String(value);
  const abbreviation = stringedValue.slice(-1);
  return abbreviation === 'k' ? `${stringedValue}` : stringedValue.toUpperCase();
};

const currencyFormat = (value, format = '0,0.00', currency = '₱', pad = true) => `${currency}${pad ? ' ' : ''}${numeral(value).format(format)}`;

const commaFormat = (value, format = '0,0') => `${numeral(value).format(format)}`;

const shortDateFormat = date => moment(date).format('L');

const serverDateFormat = date => moment(date).format('YYYY-MM-DD');

// Replace underscore with spaces and capitalize words
const humanize = (str) => {
  const removedUnderscore = str.split('_');
  const capitalizedWords = removedUnderscore.map(
    word => word.slice(0, 1).toUpperCase() + word.slice(1),
  );
  const joinedWords = capitalizedWords.join(' ');
  // Capitalize words that has slash symbol
  const removeSlashed = joinedWords.split('/');
  const mappedSlashed = removeSlashed.map(word => word.slice(0, 1).toUpperCase() + word.slice(1));
  // Capitalize words that has dash symbol
  const joinedMappedWords = mappedSlashed.join('/');
  const removeDashed = joinedMappedWords.split('-');
  const mappedDashed = removeDashed.map(word => word.slice(0, 1).toUpperCase() + word.slice(1));
  return mappedDashed.join('-');
};

const time24HourFormat = date => moment(date).format('HH:mm');

const dateFormat = date => moment(date).format('YYYY-MM-DD');

const timestampToMonthDay = date => moment(date).format('MMM. DD');

const timestampToDate = (date, showYear = false) => {
  const parsedDate = moment(date);
  if (showYear) return parsedDate.format('MMM. D, YYYY');

  const now = moment();
  return now.isSame(date, 'year') ? parsedDate.format('MMM. D') : parsedDate.format('MMM. D, YYYY');
};

const timestampToDayOfWeek = date => moment(date).format('dddd');

const timestampToDateTime = date => moment(date).format('MMMM D, YYYY h:mm a');

const normalizeMobile = (mobile) => {
  let normalizedMobile = mobile;
  if (mobile.startsWith('0')) {
    normalizedMobile = normalizedMobile.replace('0', '');
  } else if (mobile.startsWith('63')) {
    normalizedMobile = normalizedMobile.replace('63', '');
  } else if (mobile.startsWith('+63')) {
    normalizedMobile = normalizedMobile.replace('+63', '');
  }
  return normalizedMobile;
};

const getCurrentVersion = () => localStorage.getItem(`${packageName}-version`);

const isLatestVersion = currentVersion => currentVersion === getCurrentVersion();

const setLatestVersion = version => localStorage.setItem(`${packageName}-version`, version);

const parseOrderNumber = (orderNumber) => {
  const string1 = orderNumber.substring(0, 4);
  const string2 = orderNumber.substring(12);
  return `${string1}...${string2}`;
};

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // ** same as Math.pow(k, i)
  return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
}

const getBlob = url => fetch(url).then(r => r.blob());

const getFileName = url => url.substring(url.lastIndexOf('/') + 1);

const hex = (c) => {
  const v = `0${c.charCodeAt(0).toString(16)}`;
  return `\\x${v.substr(v.length - 2)}`;
};

const stringEscape = s => (s ? s
  .replace(/\\/g, '\\\\')
  .replace(/\n/g, '\\n')
  .replace(/\t/g, '\\t')
  .replace(/\v/g, '\\v')
  .replace(/'/g, "\\'")
  .replace(/"/g, '\\"')
  // eslint-disable-next-line
  .replace(/[\x00-\x1F\x80-\x9F]/g, hex) : s);

export {
  currencySymbol,
  currencyFormat,
  shortDateFormat,
  humanize,
  dateFormat,
  time24HourFormat,
  timestampToMonthDay,
  timestampToDate,
  timestampToDayOfWeek,
  timestampToDateTime,
  normalizeMobile,
  serverDateFormat,
  getCurrentVersion,
  isLatestVersion,
  setLatestVersion,
  parseOrderNumber,
  formatBytes,
  getBlob,
  getFileName,
  fixAbbreviation,
  commaFormat,
  stringEscape,
};
