import React from 'react';

const TotalDeliveryFeeIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} fill="none" {...props}>
    <path
      fill="#8D222C"
      d="M28.564.501c-.606.185-.79.527-.79 1.459v.66l-.326.113c-.835.264-1.555.888-1.89 1.644-.149.325-.193.598-.193 1.248-.017 1.59.378 2.04 2.936 3.34 1.898.967 2.197 1.204 2.197 1.723 0 .421-.193.852-.475 1.028-.395.264-1.133.369-1.705.237-.659-.15-1.02-.5-1.107-1.08-.123-.827-.826-1.24-1.441-.853-.37.228-.484.545-.422 1.133.105 1.073.817 2.004 1.872 2.426l.51.211.043.73c.027.404.097.817.159.922.29.528.975.624 1.441.211.281-.246.29-.28.316-1.046l.036-.782.36-.114c1.415-.413 2.259-1.494 2.259-2.892 0-1.564-.484-2.118-2.953-3.375-1.96-1.002-2.18-1.186-2.171-1.819 0-.255.07-.536.158-.668.474-.73 2.426-.747 2.883-.018.07.106.158.378.184.598.08.51.255.774.624.932.624.255 1.275-.255 1.275-1.002 0-.14-.08-.519-.176-.826-.281-.923-1.063-1.67-2.048-1.96l-.369-.106-.044-.738c-.053-.765-.158-1.072-.422-1.204-.22-.114-.571-.185-.72-.132ZM2.32 12.823c-.404.246-.474.563-.474 2.224 0 1.582.052 1.846.457 2.127.105.079.334.14.51.14.175 0 .404-.061.51-.14.368-.255.456-.572.456-1.661v-1.011h4.114l.096.237c.053.14 1.81 4.676 3.92 10.09 3.226 8.315 3.867 9.879 4.087 10.09l.273.237 10.678-.017 10.688-.027.193-.228c.106-.123 1.345-3.261 2.76-6.97 1.995-5.23 2.566-6.812 2.522-7.005-.07-.325-.44-.677-.782-.738-.94-.158-27.132-3.735-27.369-3.735-.229 0-.378.079-.607.307-.28.281-.307.352-.263.668.053.422.334.747.694.826.14.036 6.047.862 13.122 1.837 7.075.976 12.885 1.793 12.911 1.82.027.026-.931 2.601-2.118 5.73l-2.17 5.686-9.66-.017-9.659-.026-3.929-10.099c-3.19-8.191-3.972-10.134-4.175-10.3l-.237-.212H5.73c-2.926 0-3.155.009-3.41.167Z"
    />
    <path
      fill="#8D222C"
      d="M18.097 22.315c-.378.378-.36.888.044 1.292l.299.3h6.829c6.213 0 6.846-.018 7.075-.15.51-.299.597-1.02.184-1.442l-.255-.255H18.352l-.255.255ZM18.404 25.84c-.5.202-.73.958-.421 1.397.325.457.079.449 9.22.449 7.197 0 8.533-.018 8.788-.123.633-.273.747-1.17.194-1.582-.247-.185-.273-.185-8.921-.203-4.773-.008-8.763.018-8.86.062ZM18.29 29.654c-.51.282-.606 1.046-.193 1.468l.255.255h8.753c6.1 0 8.807-.026 8.957-.097.272-.123.5-.518.5-.87 0-.202-.087-.369-.298-.58l-.3-.299h-8.727c-7.084.009-8.78.027-8.947.123ZM20.417 36.343c-3.155.852-4.166 4.781-1.81 7.031.536.518 1.432.967 2.214 1.099 1.494.272 3.164-.449 4.052-1.75.607-.878.861-1.968.703-2.97-.387-2.417-2.839-4.034-5.159-3.41Zm1.696 1.845c1.943.572 2.189 3.305.37 4.21-1.398.703-3.077-.202-3.288-1.766-.131-1.029.537-2.1 1.503-2.417.554-.185.87-.194 1.415-.027ZM31.21 36.352c-2.153.58-3.507 2.856-2.953 4.983.43 1.643 1.74 2.856 3.392 3.138 1.284.22 2.593-.22 3.56-1.178 1.063-1.064 1.485-2.505 1.134-3.876-.501-1.925-2.145-3.226-4.087-3.2-.317 0-.782.062-1.046.133Zm2.1 1.968c.8.405 1.222 1.108 1.231 2.022 0 1.327-.932 2.276-2.241 2.285-1.283 0-2.25-.958-2.259-2.241-.009-1.099.668-1.987 1.714-2.25.404-.106 1.169-.018 1.555.184Z"
    />
  </svg>
);

export default TotalDeliveryFeeIcon;
