import React from 'react';

const CalendarIcon = props => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10.0001 17.1666C13.6667 17.1666 16.6667 14.1666 16.6667 10.5C16.6667 6.83329 13.6667 3.83329 10.0001 3.83329C6.33341 3.83329 3.33341 6.83329 3.33341 10.5C3.33341 14.1666 6.33341 17.1666 10.0001 17.1666ZM10.0001 2.16663C14.5834 2.16663 18.3334 5.91663 18.3334 10.5C18.3334 15.0833 14.5834 18.8333 10.0001 18.8333C5.41675 18.8333 1.66675 15.0833 1.66675 10.5C1.66675 5.91663 5.41675 2.16663 10.0001 2.16663ZM14.1667 12.0833L13.5834 13.1666L9.16675 10.75V6.33329H10.4167V9.99996L14.1667 12.0833Z" fill="#888888" />
  </svg>
);

export default CalendarIcon;
