import React from 'react';

const TotalRevenueIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} fill="none" {...props}>
    <path
      fill="#8D222C"
      d="M12.428.22c-2.136.466-3.753 1.968-4.096 3.797-.088.465-.08.624.062 1.125.421 1.582 2.127 2.75 5.185 3.56 1.81.483 2.944.614 5.23.614 1.863 0 2.091-.017 2.337-.167.457-.28.528-.588.308-1.432-.615-2.32-1.204-3.586-2.329-4.984C18.175 1.556 16.497.51 15.1.23c-.809-.167-1.934-.167-2.672-.01Zm2.276 1.845c1.626.343 2.98 1.477 3.92 3.296.325.624.8 1.837.8 2.04 0 .06-.484.078-1.556.043-1.802-.052-3.076-.28-4.816-.861-1.275-.422-2.004-.817-2.488-1.345-.316-.342-.369-.457-.369-.773 0-.484.396-1.178.897-1.591 1.002-.809 2.267-1.099 3.612-.809ZM29.742.22c-1.257.272-2.285.835-3.33 1.819-1.398 1.318-2.33 3.041-2.945 5.432-.237.94-.229 1.169.079 1.511l.255.29 2.136.027c2.39.026 3.146-.053 5.141-.554 3.48-.87 5.344-2.285 5.467-4.13.044-.625-.202-1.433-.615-2.084C35.253 1.468 33.812.51 32.45.22c-.774-.159-1.97-.159-2.708 0Zm2.259 1.837c1.45.307 2.61 1.344 2.646 2.373.035.94-1.39 1.819-4.017 2.496-1.59.404-2.733.553-4.043.527-1.336-.026-1.266.07-.773-1.169.474-1.213.94-1.942 1.73-2.724 1.354-1.345 2.831-1.846 4.457-1.503ZM12.78 11.285c-.361.158-.563.457-.563.844 0 .395.202.686.58.844.44.184 18.967.184 19.406 0 .378-.159.58-.449.58-.844 0-.396-.202-.686-.58-.844-.43-.184-19.01-.176-19.424 0ZM12.533 15.97C7.541 25.936 3.2 35.7 2.101 39.419c-.255.861-.273 1.02-.229 1.767.062 1.01.299 1.59.95 2.276.588.624 1.16.984 1.968 1.24l.668.21H39.63l.703-.22c1.213-.386 2.127-1.133 2.575-2.118.167-.369.202-.58.202-1.485 0-.984-.026-1.125-.342-2.11-1.222-3.805-4.527-11.258-9.282-20.944l-1.52-3.094H13.052l-.519 1.029Zm19.565 3.515c4.623 9.475 7.655 16.295 8.806 19.864.264.817.317 1.116.317 1.74v.747l-.352.387c-.193.21-.562.474-.817.589l-.457.21H5.494l-.405-.184c-.484-.229-1.01-.677-1.187-1.01-.07-.142-.132-.52-.14-.845-.01-.668.334-1.819 1.204-4.069 1.547-3.964 5.546-12.683 8.754-19.099l.518-1.028H30.77l1.328 2.698Z"
    />
    <path
      fill="#8D222C"
      d="M22.078 19.731c-.378.167-.545.58-.545 1.345v.607l-.474.105c-1.1.246-2.347 1.081-2.822 1.89-.958 1.634-.764 3.797.449 5.01.588.597.975.826 3.533 2.109 2.988 1.512 3.304 1.775 3.41 2.848.176 1.67-.985 2.698-3.041 2.689-1.002 0-1.67-.176-2.215-.589-.492-.378-.747-.861-.853-1.608-.079-.51-.131-.642-.36-.853-.5-.448-1.3-.237-1.476.404-.132.475.035 1.415.369 2.127.536 1.1 1.529 1.864 2.874 2.215l.606.15v.606c0 .325.053.73.123.897.132.307.519.57.844.57.325 0 .712-.263.844-.57.07-.167.123-.572.123-.897v-.598l.536-.105c1.186-.228 2.39-1.028 2.953-1.969.782-1.318.738-3.348-.105-4.491-.51-.694-1.222-1.178-3.34-2.241-3.753-1.89-4.008-2.11-4-3.463.01-.932.387-1.556 1.214-1.995.993-.536 2.434-.572 3.55-.088.747.325 1.301 1.134 1.301 1.916 0 .668.571 1.222 1.134 1.099.307-.062.677-.449.756-.765.096-.37-.114-1.397-.405-2.004-.527-1.125-1.731-2.03-3.04-2.294l-.554-.114v-.598c0-.782-.167-1.178-.563-1.345-.378-.158-.457-.158-.826 0Z"
    />
  </svg>
);

export default TotalRevenueIcon;
