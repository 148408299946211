/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const getBannersAction = createAction('PIEZA_ADMIN_GET_BANNERS');
export const getBannerAction = createAction('PIEZA_ADMIN_GET_BANNER');
export const createBannerAction = createAction('PIEZA_ADMIN_CREATE_BANNER');
export const updateBannerAction = createAction('PIEZA_ADMIN_UPDATE_BANNER');
export const deleteBannerAction = createAction('PIEZA_ADMIN_DELETE_BANNER');
