import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LocaleService from '@services/LocaleService';

class Search extends Component {
  searchTimeout;

  handleInputChange = (event) => {
    event.preventDefault();
    const { value } = event.target;

    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      const { onSearchChange } = this.props;
      onSearchChange(value);
    }, 500);
  }

  render() {
    const i18n = LocaleService.getTranslations('general');
    const { defaultValue, className } = this.props;

    return (
      <div className={`search-sm d-inline-block float-md-left mr-1 mb-1 align-top ${className}`}>
        <input
          type="text"
          name="keyword"
          id="search"
          className="b3"
          placeholder={i18n.table.placeholder.search}
          onChange={this.handleInputChange}
          defaultValue={defaultValue}
        />
      </div>
    );
  }
}

Search.defaultProps = {
  className: '',
  defaultValue: '',
  onSearchChange: () => {},
};

Search.propTypes = {
  className: PropTypes.string,
  onSearchChange: PropTypes.func,
  defaultValue: PropTypes.string,
};

export default Search;
