import React from 'react';

const DashboardIcon = props => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1164_7356)">
      <path d="M9.28907 0.572343C3.98103 2.31641 0.454975 6.67659 0.113744 11.9088L0 13.7287L6.93837 13.8424L13.8388 13.9183L13.763 7.01782L13.6492 0.0794535L12.5118 0.00362396C11.9052 -0.0342903 10.4265 0.231112 9.28907 0.572343ZM12.3222 7.09365V12.4017H6.97628H1.59241L1.85781 10.8472C2.23696 8.5344 3.33648 6.63867 5.49761 4.70503C7.35543 3.03679 9.74405 1.86144 11.4881 1.82352C12.3222 1.78561 12.3222 1.82352 12.3222 7.09365Z" fill="currentColor" />
      <path d="M16.4928 2.92303C15.507 3.52966 16.417 4.32587 18.3127 4.55336C22.4454 5.04625 26.0852 7.81401 27.526 11.5676C28.7013 14.7145 28.4359 19.0367 26.9193 21.8424C23.1658 28.7049 13.8388 30.5248 8.22745 25.4822C5.95257 23.4348 4.81513 21.2737 4.43599 18.2405C4.24642 16.6481 4.01893 16.0036 3.60187 16.0036C2.54026 16.0036 2.9194 20.5533 4.13267 22.9798C6.52129 27.6433 10.4644 29.9561 16.1137 29.994C19.0331 30.032 19.6018 29.9182 21.8767 28.7808C27.0331 26.2405 29.8767 21.6908 29.8767 16.0036C29.8388 8.87562 25.3648 3.71924 18.5402 2.8472C17.6682 2.73346 16.7582 2.77137 16.4928 2.92303Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_1164_7356">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export default DashboardIcon;
