/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const getShippingLocationsAction = createAction('PIEZA_ADMIN_GET_SHIPPING_LOCATIONS');
export const createShippingLocationAction = createAction('PIEZA_ADMIN_CREATE_SHIPPING_LOCATION');
export const showShippingLocationAction = createAction('PIEZA_ADMIN_SHOW_SHIPPING_LOCATION');
export const updateShippingLocationAction = createAction('PIEZA_ADMIN_UPDATE_SHIPPING_LOCATION');
export const deleteShippingLocationAction = createAction('PIEZA_ADMIN_DELETE_SHIPPING_LOCATION');

export const getShippingClassesAction = createAction('PIEZA_ADMIN_GET_SHIPPING_CLASSES');
