import React from 'react';

const DeleteIcon = props => (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1164_8480)">
      <path d="M19.2672 3.91653L14.4273 3.91655V2.04174C14.4273 1.17102 13.7181 0.484207 12.8474 0.484207H9.15244C8.28172 0.484207 7.57291 1.17102 7.57291 2.04174V3.91655H2.73291C2.35339 3.91655 2.04541 4.22455 2.04541 4.60405C2.04541 4.98355 2.35339 5.29155 2.73291 5.29155H3.67854L5.27354 21.1883C5.34023 21.9321 5.97411 22.5155 6.71592 22.5155H15.2839C16.0257 22.5155 16.6589 21.9321 16.7252 21.1948L18.3216 5.29121H19.2672C19.6471 5.29121 19.9547 4.98321 19.9547 4.60371C19.9547 4.22421 19.6471 3.91653 19.2672 3.91653H19.2672ZM8.94789 2.04176C8.94789 1.92935 9.03965 1.83755 9.15242 1.83755H12.8474C12.9601 1.83755 13.0523 1.92935 13.0523 2.04176V3.91657H8.94789V2.04176ZM15.3564 21.0649C15.353 21.1031 15.308 21.1409 15.2839 21.1409H6.71592C6.69186 21.1409 6.64648 21.1034 6.64234 21.0584L5.0604 5.29192H16.9394L15.3564 21.0649Z" fill="#CF1827" />
    </g>
    <defs>
      <clipPath id="clip0_1164_8480">
        <rect width="22" height="22" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>

);

export default DeleteIcon;
