export default {
  // prevent redirection if 401 is received
  whitelistUrl: [
    '/admin/auth/login',
  ],
  appVersion: process.env.REACT_APP_VERSION || '1.0.0',
  apiUrl: `${process.env.REACT_APP_API_URL}`,
  ftpUrl: `${process.env.REACT_APP_API_URL}`,
  env: process.env.REACT_APP_DEVELOPMENT_ENV,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  sentryPropagationTargets: process.env.REACT_APP_SENTRY_PROPAGATION_TARGETS || 'localhost',
};
