import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { IntlProvider } from 'react-intl';
import ReduxToastr from 'react-redux-toastr';
import { createBrowserHistory } from 'history';

import { MainWrapper } from '@providers/MainProvider';

import CacheBuster from '@shared/utils/CacheBuster';
import { LoadingBlock } from '@shared/utils';

import LocaleService from '@services/LocaleService';

import * as Sentry from '@sentry/react';
import appConfig from '@config/app';
import { stringEscape } from '@shared/helpers';
import { store, persistor } from './store';

const urlPropagate = new RegExp(`^${stringEscape(appConfig.apiUrl)}`);
const history = createBrowserHistory();

// if (appConfig.env !== 'local') {
Sentry.init({
  dsn: appConfig.sentryDSN,
  environment: appConfig.env,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV5BrowserTracingIntegration({
      history,
    }),
    // or
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [ urlPropagate ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
// }

const i18n = LocaleService.getTranslations('menu');
const LazyAppRouter = lazy(() => import('@app/AppRouter'));
const App = () => (
  <CacheBuster>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IntlProvider messages={i18n}>
          <Suspense fallback={<LoadingBlock />}>
            <MainWrapper>
              <LazyAppRouter history={history} />
              <ReduxToastr
                timeOut={4000}
                newestOnTop={false}
                preventDuplicates
                position="top-left"
                getState={state => state.toastr} // This is the default
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
                closeOnToastrClick
              />
            </MainWrapper>
          </Suspense>
        </IntlProvider>
      </PersistGate>
    </Provider>
  </CacheBuster>
);

export default App;
