import React from 'react';

const Checkbox = () => (
  <>
    <svg
      width={25}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={25} height={25} rx={6} fill="#1E090C" />
      <path
        d="M10.317 17.5 5.5 12.734l.925-.915 3.892 3.851 8.258-8.17.925.915-9.183 9.085Z"
        fill="#fff"
        stroke="#fff"
        strokeLinejoin="round"
      />
    </svg>
  </>
);

export default Checkbox;
