/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { isLatestVersion, setLatestVersion } from '@shared/helpers';

import appConfig from '@config/app';

import LoadingBlock from '../LoadingBlock';

const appVersion = appConfig.version;

class CacheBuster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const response = await axios.get('/meta.json').catch(() => {
      console.log('Error fetching metadata');
    });

    this.setState({ loading: false });
    // by default get the app version
    let buildVersion = appVersion;
    if (response) {
      const meta = response.data;
      buildVersion = meta.version;
    }

    if (!isLatestVersion(buildVersion)) {
      console.log(`We have a new version - ${buildVersion}. Should force refresh`);
      // set the latest version
      setLatestVersion(buildVersion);
      this.refreshCacheAndReload();
      this.setState({ loading: false });
    } else {
      console.log(`You already have the latest version - ${buildVersion}. No cache refresh needed.`);
      this.setState({ loading: false });
    }
  }

  refreshCacheAndReload = () => {
    console.log('Clearing cache and hard reloading...');
    if (window.caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const name of names) caches.delete(name);
      });
    }
    // delete browser cache and hard reload
    // window.location.reload(true);
  };

  render() {
    const { children } = this.props;
    const { loading } = this.state;

    if (loading) return <LoadingBlock enabled />;

    return children;
  }
}

CacheBuster.defaultProps = {
  children: null,
};

CacheBuster.propTypes = {
  children: PropTypes.any,
};

export default CacheBuster;
