import React from 'react';

const LeftArrow = () => (
  <>
    <svg width="18" height="15" viewBox="0 -2 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 1 1 4l3 3"
        stroke="#4A4A4A"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

  </>
);

export default LeftArrow;
