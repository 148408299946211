import React from 'react';

const PabiliIcon = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.68559 0.0285749C6.38665 0.0666752 5.99099 0.186836 5.7155 0.324583C5.07366 0.641106 4.63112 1.17451 4.44062 1.85738C4.37907 2.08304 4.37614 2.15338 4.36442 3.54843L4.35269 5.00795H3.83102C3.3533 5.00795 3.30348 5.01381 3.23314 5.06657C3.16866 5.11639 2.92248 5.8315 1.57139 9.89061C0.287716 13.7387 -0.014153 14.6707 0.00050085 14.7498C0.0122239 14.8026 0.0532547 14.8788 0.0942855 14.9169L0.170485 14.9872L4.48165 14.996C8.43526 15.0048 8.80453 15.0019 8.88659 14.9579C9.0683 14.8612 9.10347 14.6121 8.954 14.4714L8.86315 14.3864H4.80696C2.57665 14.3864 0.750778 14.3776 0.750778 14.363C0.750778 14.3512 1.40434 12.3847 2.20151 9.99026L3.64931 5.63807L4.001 5.62928L4.35269 5.62048L4.36149 6.31508L4.37028 7.00674L4.46699 7.09173C4.59888 7.20896 4.75714 7.20896 4.88902 7.09173L4.98574 7.00674L4.99453 6.31508L5.00332 5.62341H7.49155H9.97977L9.98856 6.31508L9.99736 7.00674L10.0941 7.09173C10.226 7.20896 10.3842 7.20896 10.5161 7.09173L10.6128 7.00674L10.6216 6.31508L10.6304 5.62341H10.9792H11.325L11.4305 5.93701C11.4862 6.11285 11.7646 6.97743 12.0489 7.86545C12.3302 8.75055 12.5794 9.50961 12.5969 9.54478C12.6409 9.63271 12.7669 9.69718 12.8871 9.69718C13.0102 9.69718 13.1772 9.53306 13.1772 9.4129C13.1772 9.36894 12.8724 8.38713 12.5032 7.23534C11.9375 5.47101 11.8203 5.12811 11.7558 5.07243C11.6825 5.01088 11.6562 5.00795 11.155 5.00795H10.6304L10.6187 3.56308C10.6099 2.18855 10.604 2.10942 10.5425 1.87203C10.2816 0.866775 9.42292 0.169251 8.2682 0.0285749C7.9634 -0.0095253 6.98746 -0.0095253 6.68559 0.0285749ZM8.50266 0.714375C8.88073 0.81109 9.18553 0.966421 9.42586 1.18916C9.65446 1.40018 9.78341 1.59068 9.89185 1.86617L9.96805 2.06253L9.97684 3.53378L9.98563 5.00795H7.49155H4.99746L5.00626 3.53378C5.01798 2.08891 5.01798 2.0596 5.08246 1.88375C5.34622 1.17451 6.00565 0.723167 6.90539 0.638175C7.04314 0.626451 7.39483 0.62059 7.68791 0.629383C8.12459 0.638175 8.2682 0.652829 8.50266 0.714375Z" fill="currentColor" />
    <path d="M12.0166 10.3683C11.8759 10.4533 11.8584 10.5471 11.8584 11.2388V11.8659H11.2282C10.7476 11.8659 10.5718 11.8747 10.4956 11.907C10.393 11.9509 10.3051 12.0799 10.3051 12.1883C10.3051 12.2968 10.393 12.4257 10.4956 12.4697C10.5718 12.5019 10.7476 12.5107 11.2282 12.5107H11.8584V13.1408C11.8584 13.8442 11.8759 13.938 12.0313 14.0171C12.1426 14.0758 12.2188 14.0758 12.3302 14.0171C12.4855 13.938 12.5031 13.8442 12.5031 13.1408V12.5107H13.1332C13.6139 12.5107 13.7897 12.5019 13.8659 12.4697C13.9685 12.4257 14.0564 12.2968 14.0564 12.1883C14.0564 12.0799 13.9685 11.9509 13.8659 11.907C13.7897 11.8747 13.6139 11.8659 13.1332 11.8659H12.5031V11.2358C12.5031 10.5324 12.4855 10.4387 12.3302 10.3595C12.213 10.298 12.128 10.3009 12.0166 10.3683Z" fill="currentColor" />
  </svg>

);

export default PabiliIcon;
