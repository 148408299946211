import React from 'react';

const VisibilityOff = () => (
  <>
    <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.45 27.05L29.25 24.85C30.1167 22.4833 29.6667 20.5167 27.9 18.95C26.1333 17.3833 24.2167 16.9833 22.15 17.75L19.95 15.55C20.5167 15.1833 21.15 14.9167 21.85 14.75C22.55 14.5833 23.2667 14.5 24 14.5C26.3667 14.5 28.375 15.325 30.025 16.975C31.675 18.625 32.5 20.6333 32.5 23C32.5 23.7333 32.4083 24.4583 32.225 25.175C32.0417 25.8917 31.7833 26.5167 31.45 27.05V27.05ZM37.9 33.5L35.9 31.5C37.5333 30.3 38.9583 28.9583 40.175 27.475C41.3917 25.9917 42.2833 24.5 42.85 23C41.1833 19.3 38.6833 16.375 35.35 14.225C32.0167 12.075 28.4 11 24.5 11C23.1 11 21.6667 11.1333 20.2 11.4C18.7333 11.6667 17.5833 11.9833 16.75 12.35L14.45 10C15.6167 9.46667 17.1083 9 18.925 8.6C20.7417 8.2 22.5167 8 24.25 8C29.0167 8 33.375 9.35833 37.325 12.075C41.275 14.7917 44.1667 18.4333 46 23C45.1333 25.1333 44.0167 27.0833 42.65 28.85C41.2833 30.6167 39.7 32.1667 37.9 33.5ZM40.8 44.8L32.4 36.55C31.2333 37.0167 29.9167 37.375 28.45 37.625C26.9833 37.875 25.5 38 24 38C19.1333 38 14.7167 36.6417 10.75 33.925C6.78333 31.2083 3.86667 27.5667 2 23C2.66667 21.2667 3.59167 19.575 4.775 17.925C5.95833 16.275 7.4 14.7 9.1 13.2L2.8 6.9L4.9 4.75L42.75 42.6L40.8 44.8ZM11.15 15.3C9.91667 16.2 8.725 17.3833 7.575 18.85C6.425 20.3167 5.6 21.7 5.1 23C6.8 26.7 9.35833 29.625 12.775 31.775C16.1917 33.925 20.0667 35 24.4 35C25.5 35 26.5833 34.9333 27.65 34.8C28.7167 34.6667 29.5167 34.4667 30.05 34.2L26.85 31C26.4833 31.1667 26.0333 31.2917 25.5 31.375C24.9667 31.4583 24.4667 31.5 24 31.5C21.6667 31.5 19.6667 30.6833 18 29.05C16.3333 27.4167 15.5 25.4 15.5 23C15.5 22.5 15.5417 22 15.625 21.5C15.7083 21 15.8333 20.55 16 20.15L11.15 15.3Z" fill="#888888" />
    </svg>
  </>
);

export default VisibilityOff;
