import { handleActions } from 'redux-actions';

import {
} from './BannerActionConfig';

// reducers
const defaultState = {
};

const reducer = handleActions({
}, defaultState);

export default reducer;
