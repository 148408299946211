import React from 'react';

const Box = () => (
  <>
    <svg
      width={25}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x={0.5} y={0.5} width={24} height={24} rx={5.5} fill="#fff" />
      <rect x={0.5} y={0.5} width={24} height={24} rx={5.5} stroke="#BFBFBF" />
    </svg>
  </>
);

export default Box;
