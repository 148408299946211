/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const getCategoryAction = createAction('PIEZA_ADMIN_GET_CATEGORY');
export const showCategoryAction = createAction('PIEZA_ADMIN_SHOW_CATEGORY');
export const createCategoryAction = createAction('PIEZA_ADMIN_CREATE_CATEGORY');
export const updateCategoryAction = createAction('PIEZA_ADMIN_UPDATE_CATEGORY');
export const deleteCategoryAction = createAction('PIEZA_ADMIN_DELETE_CATEGORY');
export const deleteSubCategoryAction = createAction('PIEZA_ADMIN_DELETE_SUB_CATEGORY');
