import React from 'react';

const CategoryIcon = props => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1164_7251)">
      <path d="M11.9385 8.4375H14.0625C14.5752 8.4375 15 8.8623 15 9.375V14.0625C15 14.5752 14.5752 15 14.0625 15H9.375C8.8623 15 8.4375 14.5752 8.4375 14.0625V9.375C8.4375 8.8623 8.8623 8.4375 9.375 8.4375H11.0303V7.88086H3.95508V8.4375H5.625C6.1377 8.4375 6.5625 8.8623 6.5625 9.375V14.0625C6.5625 14.5752 6.1377 15 5.625 15H0.9375C0.424805 15 0 14.5752 0 14.0625V9.375C0 8.8623 0.424805 8.4375 0.9375 8.4375H3.04688V7.55859C3.04688 7.23633 3.45703 7.0752 3.82324 7.0752H7.0459V6.54785H5.02441C4.51172 6.54785 4.08691 6.12305 4.08691 5.61035V0.922852C4.08691 0.410156 4.51172 -0.0146484 5.02441 -0.0146484H9.71191C10.2246 -0.0146484 10.6494 0.410156 10.6494 0.922852V5.61035C10.6494 6.12305 10.2246 6.54785 9.71191 6.54785H7.9834V7.08984C9.19922 7.08984 9.94629 7.0752 11.1621 7.0752C11.5283 7.0752 11.9385 7.22168 11.9385 7.55859V8.4375ZM14.0625 9.375H9.375V14.0625H14.0625V9.375ZM9.71191 0.922852H5.02441V5.61035H9.71191V0.922852ZM5.625 9.375H0.9375V14.0625H5.625V9.375Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_1164_7251">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CategoryIcon;
