/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const loginAction = createAction('PIEZA_ADMIN_AUTH_LOGIN');
export const logoutAction = createAction('PIEZA_ADMIN_AUTH_LOGOUT');
export const clearUserTokenAction = createAction('PIEZA_ADMIN_USER_CLEAR_TOKEN');
export const getUserDetailsAction = createAction('PIEZA_ADMIN_UTH_GET_USER_DETAILS');
export const updateInformationAction = createAction('PIEZA_AUTH_UPDATE_INFORMATION');

export const sendCodeAction = createAction('PIEZA_ADMIN_AUTH_SEND_CODE');
export const verifyCodeAction = createAction('PIEZA_ADMIN_AUTH_VERIFY_CODE');
export const changePasswordAction = createAction('PIEZA_ADMIN_AUTH_CHANGE_PASSWORD');
