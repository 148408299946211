import React from 'react';

const MinusBox = () => (
  <>
    <svg
      width={25}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={25} height={25} rx={6} fill="#1E090C" />
      <rect x={5} y={11.5} width={15} height={2} rx={0.5} fill="#fff" />
    </svg>
  </>
);

export default MinusBox;
