import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import AuthReducer from '@reducers/auth/AuthReducer';
import LoadingReducer from '@reducers/loading/LoadingReducer';
import MenuReducer from '@reducers/menu/MenuReducer';
import SettingsReducer from '@reducers/settings/SettingsReducer';
import CategoryReducer from '@reducers/category/CategoryReducer';
import ProductReducer from '@reducers/product/ProductReducer';
import VoucherReducer from '@reducers/voucher/VoucherReducer';
import VehicleReducer from '@reducers/vehicle/VehicleReducer';
import TransactionReducer from '@reducers/transaction/TransactionReducer';
import DashboardDataReducer from '@reducers/dashboard/DashboardDataReducer';
import ShippingLocationReducer from '@reducers/shipping-location/ShippingLocationReducer';
import { reducer as toastrReducer } from 'react-redux-toastr';
import BannerReducer from '@reducers/banner/BannerReducer';
import ReasonReducer from '@reducers/reasons/ReasonsReducer';

import persistConfig from '@config/redux-persist';

const reducers = combineReducers({
  Auth: persistReducer(persistConfig.auth, AuthReducer),
  Loading: LoadingReducer,
  Menu: MenuReducer,
  Vehicle: VehicleReducer,
  Category: CategoryReducer,
  Voucher: VoucherReducer,
  Product: ProductReducer,
  Settings: SettingsReducer,
  Transaction: TransactionReducer,
  Dasboard: DashboardDataReducer,
  ShippingLocation: ShippingLocationReducer,
  toastr: toastrReducer,
  Banner: BannerReducer,
  Reason: ReasonReducer,
});

export default reducers;
