import React from 'react';

const CloseIcon = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M10 7.56947L2.43053 0L0 2.43053L7.56947 10L0 17.5695L2.43053 20L10 12.4305L17.5695 20L20 17.5695L12.4305 10L20 2.43053L17.5695 0L10 7.56947Z" fill="#888888" />
  </svg>

);

export default CloseIcon;
