import React from 'react';

const Check = props => (
  <>
    <svg
      width={24}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.726 16.5 1.5 9.351 2.887 7.98l5.839 5.776L21.113 1.5 22.5 2.872 8.726 16.5Z"
        fill="#fff"
        stroke="#fff"
      />
    </svg>
  </>
);

export default Check;
