import React from 'react';

const TransactionIcon = props => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M29.7557 8.32031C29.6697 8.19818 29.5556 8.0985 29.4231 8.02966C29.2905 7.96082 29.1434 7.92484 28.994 7.92474H9.09745L7.59135 2.73099C7.00073 0.685839 5.59726 0.465057 5.02164 0.465057H1.00586C0.49114 0.465057 0.0748901 0.881776 0.0748901 1.39599C0.0748901 1.91021 0.491609 2.3269 1.00583 2.3269H5.02114C5.14817 2.3269 5.53583 2.3269 5.80023 3.2405L10.9813 22.2816C11.0938 22.6833 11.4599 22.9608 11.8775 22.9608H24.5329C24.9257 22.9608 25.2763 22.7147 25.409 22.3448L29.8696 9.17062C29.9723 8.88515 29.9296 8.56734 29.7557 8.32031H29.7557ZM23.8777 21.0994H12.5842L9.62072 9.78709H27.6704L23.8777 21.0994ZM22.0313 24.8476C20.7367 24.8476 19.6876 25.8966 19.6876 27.1913C19.6876 28.486 20.7367 29.5351 22.0313 29.5351C23.326 29.5351 24.3751 28.486 24.3751 27.1913C24.3751 25.8966 23.326 24.8476 22.0313 24.8476ZM13.5938 24.8476C12.2992 24.8476 11.2501 25.8966 11.2501 27.1913C11.2501 28.486 12.2992 29.5351 13.5938 29.5351C14.8885 29.5351 15.9376 28.486 15.9376 27.1913C15.9376 25.8966 14.8885 24.8476 13.5938 24.8476Z" fill="currentColor" />
  </svg>
);

export default TransactionIcon;
