// import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Modal, ModalBody, Row, Col,
} from 'reactstrap';

import AsyncButton from '@shared/buttons/AsyncButton/AsyncButton';
import LocaleService from '@services/LocaleService';

import { CloseIcon } from '@shared/icons';
import './ConfirmDialogStyle.scss';

const i18n = LocaleService.getTranslations('general.button');

export const ConfirmDialog = {
  show: () => null,
  close: () => null,
};

export const useConfirmDialog = () => ConfirmDialog;

const ConfirmDialogRoot = () => {
  // const [ buttons, setButtons ] = useState();
  const [ modalData, setModalData ] = useState({
    title: '',
    message: '',
    confirmText: '',
  });
  const [ showModal, setShowModal ] = useState(false);
  const [ method, setMethod ] = useState({ onConfirm: null, onCancel: null });


  const onShow = ({
    title,
    message,
    confirmText,
    onConfirm = null,
    onCancel = null,
  }) => {
    setModalData({ title, message, confirmText });
    setMethod({ onConfirm, onCancel });
    setShowModal(true);
  };

  useEffect(() => {
    ConfirmDialog.show = onShow;
    ConfirmDialog.close = () => setShowModal(false);
  }, []);

  const handleConfirm = () => {
    if (method.onConfirm) {
      method.onConfirm();
    }
    setShowModal(false);
  };

  const handleCancel = () => {
    if (method.onCancel) {
      method.onCancel();
    }
    setShowModal(false);
  };

  return (
    <Modal
      isOpen={showModal}
      centered
      className="custom-modal-dialog-centered"
      toggle={handleCancel}
    >
      <ModalBody>
        <Row>
          <Col xs={10} className="title2 mb-4">
            {modalData.title}
          </Col>
          <Col xs={2} className="text-right">
            <a href="javascript:;" onClick={handleCancel}>
              <CloseIcon />
            </a>
          </Col>
        </Row>
        <Row>
          <Col className="body mb-4">
            {typeof modalData.message === 'function' ? modalData.message() : modalData.message}
          </Col>
        </Row>
        <Row>
          <Col>
            <AsyncButton
              color="primary"
              outline
              block
              onClick={handleCancel}
              className="confirm-button"
              title={i18n.cancel.title}
            >
              {i18n.cancel.name}
            </AsyncButton>
          </Col>
          <Col>
            <AsyncButton
              color="primary"
              block
              onClick={handleConfirm}
              className="confirm-button"
              title={modalData.confirmText || i18n.confirm.title}
            >
              {modalData.confirmText || i18n.confirm.name}
            </AsyncButton>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

ConfirmDialogRoot.defaultProps = {

};

ConfirmDialogRoot.propTypes = {
  // show: PropTypes.bool.isRequired,
};

export default ConfirmDialogRoot;
