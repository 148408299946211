/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const getVoucherAction = createAction('PIEZA_ADMIN_GET_VOUCHER');
export const showVoucherAction = createAction('PIEZA_ADMIN_SHOW_VOUCHER');
export const createVoucherAction = createAction('PIEZA_ADMIN_CREATE_VOUCHER');
export const updateVoucherAction = createAction('PIEZA_ADMIN_UPDATE_VOUCHER');
export const deleteVoucherAction = createAction('PIEZA_ADMIN_DELETE_VOUCHER');
