import React from 'react';

const CalendarIcon = props => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M3.75 18.8333C3.41667 18.8333 3.125 18.7083 2.875 18.4583C2.625 18.2083 2.5 17.9166 2.5 17.5833V4.66663C2.5 4.33329 2.625 4.04163 2.875 3.79163C3.125 3.54163 3.41667 3.41663 3.75 3.41663H5.10417V2.16663H6.45833V3.41663H13.5417V2.16663H14.8958V3.41663H16.25C16.5833 3.41663 16.875 3.54163 17.125 3.79163C17.375 4.04163 17.5 4.33329 17.5 4.66663V17.5833C17.5 17.9166 17.375 18.2083 17.125 18.4583C16.875 18.7083 16.5833 18.8333 16.25 18.8333H3.75ZM3.75 17.5833H16.25V8.62496H3.75V17.5833ZM3.75 7.37496H16.25V4.66663H3.75V7.37496ZM3.75 7.37496V4.66663V7.37496Z" fill="currentColor" />
  </svg>

);

export default CalendarIcon;
