import { handleActions } from 'redux-actions';

import {
  getProductsAction,
  createProductAction,
  deleteProductAction,
  updateProductAction,
  getImportLogProductAction,
} from './ProductActionConfig';

// reducers
const defaultState = {
  products: {
    data: [],
    meta: {
      total: 0,
    },
  },
  logs: {
    data: [],
    meta: {
      total: 0,
    },
  },
};

const reducer = handleActions({
  [getImportLogProductAction](state, { payload }) {
    return {
      ...state,
      logs: payload,
    };
  },
  [getProductsAction](state, { payload }) {
    return {
      ...state,
      products: payload,
    };
  },
  [createProductAction](state, { payload }) {
    return {
      ...state,
      products: {
        ...state.products,
        data: [
          { ...payload },
          ...state.products.data,
        ],
        meta: {
          ...state.products.meta,
          total: state.products.meta.total + 1,
        },
      },
    };
  },
  [updateProductAction](state, { payload }) {
    return {
      ...state,
      products: {
        ...state.products,
        data: state.products.data
          .map((product) => {
            if (product.id === payload.id) {
              return {
                ...product,
                ...payload,
              };
            }

            return product;
          }),
      },
    };
  },
  [deleteProductAction](state, { params }) {
    return {
      ...state,
      products: {
        ...state.products,
        data: state.products.data
          .filter(product => product.id !== params.productId),
        meta: {
          ...state.products.meta,
          total: state.products.meta.total - 1,
        },
      },
    };
  },
}, defaultState);

export default reducer;
