import { handleActions } from 'redux-actions';

import {
  getShippingLocationsAction,
  createShippingLocationAction,
  deleteShippingLocationAction,
  updateShippingLocationAction,
} from './ShippingLocationActionConfig';

// reducers
const defaultState = {
  shippingLocations: {
    data: [],
    meta: {
      total: 0,
    },
  },
};

const reducer = handleActions({
  [getShippingLocationsAction](state, { payload }) {
    return {
      ...state,
      shippingLocations: payload,
    };
  },
  [createShippingLocationAction](state, { payload }) {
    return {
      ...state,
      shippingLocations: {
        ...state.shippingLocations,
        data: [
          { ...payload },
          ...state.shippingLocations.data,
        ],
        meta: {
          ...state.shippingLocations.meta,
          total: state.shippingLocations.meta.total + 1,
        },
      },
    };
  },
  [updateShippingLocationAction](state, { payload }) {
    return {
      ...state,
      shippingLocations: {
        ...state.shippingLocations,
        data: state.shippingLocations.data
          .map((location) => {
            if (location.id === payload.id) {
              return {
                ...location,
                ...payload,
              };
            }

            return location;
          }),
      },
    };
  },
  [deleteShippingLocationAction](state, { params }) {
    return {
      ...state,
      shippingLocations: {
        ...state.shippingLocations,
        data: state.shippingLocations.data
          .filter(location => location.id !== params.locationId),
        meta: {
          ...state.shippingLocations.meta,
          total: state.shippingLocations.meta.total - 1,
        },
      },
    };
  },
}, defaultState);

export default reducer;
